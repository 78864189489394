<template>
  <div>
    <div class="title">用户资料</div>
    <el-divider></el-divider>
    <div class="user-info">
      <img src="@/assets/avatar.png" alt="" />
      <div class="content">
        <div class="item">
          <span class="icon iconfont icon-id-card"></span>
          <span class="label">账号：</span>
          <span class="text">{{ user.id }}</span>
        </div>
        <div class="item">
          <span class="icon iconfont icon-person"></span>
          <span class="label">昵称：</span>
          <span class="text">{{ user.nickName }}</span>
        </div>
        <div class="item">
          <i class="icon el-icon-phone"></i>
          <span class="label">手机号：</span>
          <span class="text">{{ user.phone }}</span>
        </div>
        <div class="item">
          <span class="icon iconfont icon-coupon"></span>
          <span class="label">优惠券：</span>
          <span class="text">{{ user.couponNum }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.simply {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px;

  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
  color: #333333;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  img {
    width: 80px;
  }
  .content {
    width: 220px;
    .item {
      margin-top: 20px;
      font-size: 18px;
      color: #666666;
      .icon {
        margin-right: 10px;
      }
      .label {
        color: #000000;
      }
    }
  }
}
</style>
